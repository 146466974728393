import { GetStaticPaths, GetStaticProps } from 'next'
import { paths, slugs } from '@/constants'
import { Durations } from '@/constants/durations'
import { ProjectMetaProvider } from '@/contexts/ProjectMetaContext'
import { TheatricalRegionProvider } from '@/contexts/TheatricalRegionContext/TheatricalRegionContext'
import { Seo } from '@/layout/Seo'
import { TVSeriesJsonLd } from '@/layout/Seo/JsonLd'
import { NextPageWithLayout } from '@/layouts'
import { getWebClient } from '@/services/ApolloClient'
import { getTheatricalReleaseBySlug } from '@/services/CmsService'
import { CatalogSeries, fetchTitle, mapJsonLdProps, mapSeoProps } from '@/services/ContentCatalog'
import { getPlayableLivestreamsByProject, PlayableLivestream } from '@/services/LivestreamService'
import { getContentfulProjectTheme } from '@/services/ProjectThemeService'
import { getProjectAbout, ProjectMeta } from '@/services/ProjectsService'
import {
  formatFilmName,
  getTheatricalPreReleasePageData,
  GetTheatricalPreReleasePageDataResult,
  isTheChosen,
  useSeriesTitle,
} from '@/services/TheatricalPreReleaseService'
import { getWhereToWatch, WhereToWatch } from '@/services/WhereToWatchService'
import { ProjectTheme, TheatricalRegion } from '@/types/codegen-contentful'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { getStaticPropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { omitUndefineds } from '@/utils/object'
import { isStreaming } from '@/utils/phases'
import { isSeries } from '@/utils/project-utils'
import { redact } from '@/utils/sensitive-data'
import { loadTranslations } from '@/utils/translate/translate-server'
import { WatchTheatricalView } from '@/views/WatchTheatricalView'
import { WatchTheatricalContextProvider } from '@/views/WatchTheatricalView/WatchTheatricalContext'

export const ShowsPage: NextPageWithLayout<
  ProjectMeta & {
    catalogTitle?: CatalogSeries
    isStreaming: boolean
    livestreams: PlayableLivestream[] | null
    locale: string
    pageData: GetTheatricalPreReleasePageDataResult
    supportedTheatricalRegions: TheatricalRegion[]
    theatricalRegion: TheatricalRegion[]
    whereToWatch: WhereToWatch
    contentfulProjectTheme: ProjectTheme
  }
> = (props) => {
  const { filmDetails } = props.pageData
  const { isStreaming, locale } = props

  const path = `${paths.shows.index}/${props.slug}`
  const catalogTitle = props.catalogTitle
  const title = useSeriesTitle({
    filmName: formatFilmName(catalogTitle),
    isStreaming,
    releaseYear: filmDetails?.releaseYear,
  })

  return (
    <>
      <Seo
        {...mapSeoProps({ path, title, catalogTitle, locale })}
        noindex={props.slug !== slugs.truthAndConviction && !props.public}
        path={path}
      />
      {catalogTitle && <TVSeriesJsonLd {...mapJsonLdProps({ path, catalogTitle, locale })} />}
      <ProjectMetaProvider project={props}>
        <TheatricalRegionProvider
          supportedTheatricalRegions={props.supportedTheatricalRegions}
          regionData={props.theatricalRegion}
        >
          <WatchTheatricalContextProvider pageData={props.pageData}>
            <WatchTheatricalView
              catalogTitle={catalogTitle}
              livestreams={props.livestreams}
              whereToWatch={props.whereToWatch}
              contentfulProjectTheme={props.contentfulProjectTheme}
            />
          </WatchTheatricalContextProvider>
        </TheatricalRegionProvider>
      </ProjectMetaProvider>
    </>
  )
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}

export const getStaticProps: GetStaticProps = async ({ preview = false, params }) =>
  getStaticPropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const locale = getLocaleFromParams(params)
    const project = (params?.projectSlug as string).toLowerCase()

    const client = getWebClient({ locale })
    let projectMeta = await getProjectAbout({ slug: project }, client, { locale, preview })

    if (!isSeries(projectMeta) || isTheChosen(project)) {
      return {
        notFound: true,
        revalidate: Durations.FIFTEEN_MINUTES_IN_SECONDS,
      }
    }

    if (!projectMeta.hasHubPage) {
      return {
        redirect: {
          destination: `${paths.watch.index}/${project}`,
          permanent: false,
        },
      }
    }

    const livestreams = await getPlayableLivestreamsByProject({ projectSlug: project }, client)
    const shouldIncludePreview = process.env.NEXT_PUBLIC_ANGEL_ENV === 'development'
    const pageData = await getTheatricalPreReleasePageData({ slug: project }, client, { locale, preview })

    const whereToWatch = await getWhereToWatch(project, preview)
    const contentfulProjectTheme = await getContentfulProjectTheme(project, preview)

    const catalogTitle = await fetchTitle<CatalogSeries>(locale, pageData.contentCatalogId)

    const theatricalRelease = await getTheatricalReleaseBySlug({
      theatricalSlug: project,
      opts: { preview: shouldIncludePreview, locale },
    })

    const regionData = theatricalRelease?.regionCollection || null
    const supportedTheatricalRegions = theatricalRelease?.supportedTheatricalRegions || null

    // Projects may have a hub page and not be in Hydra so these values are undefined
    if (projectMeta.slug === undefined) {
      projectMeta = { ...projectMeta, slug: project }
    }

    if (projectMeta.public === undefined || (!projectMeta.public && pageData?.filmDetails?.sys?.id && catalogTitle)) {
      projectMeta = { ...projectMeta, public: true }
    }

    return {
      props: {
        catalogTitle: omitUndefineds(catalogTitle),
        isStreaming: isStreaming(projectMeta.phases) ?? false,
        livestreams,
        locale,
        pageData,
        supportedTheatricalRegions,
        theatricalRegion: regionData,
        whereToWatch,
        contentfulProjectTheme,
        ...redact(projectMeta, ['phases']),
        ...(await loadTranslations(locale, [
          'common',
          'home',
          'watch',
          'app-promo',
          'ads',
          'account',
          'theatrical-presales',
          'pif',
          'guild',
        ])),
      },
      revalidate: catalogTitle ? Durations.FIFTEEN_MINUTES_IN_SECONDS : Durations.ONE_MINUTE_IN_SECONDS,
    }
  })

export default ShowsPage
